import * as yup from 'yup';
import * as MESSAGES from '../constants/constants';
import { validateBIC, validateIBAN } from 'ibantools';

export const differentProviderLedgerValidationSchema = () =>
  yup.object().shape({
    ledgerName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    fee: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    iban: yup
      .string()
      .required(MESSAGES.REQUIRE_MESSAGE)
      .test((value) => {
        return validateIBAN(value).valid;
      }),
    bic: yup
      .string()
      .required(MESSAGES.REQUIRE_MESSAGE)
      .test((value) => {
        return validateBIC(value).valid;
      }),
    balance: yup
      .number()
      .typeError(MESSAGES.TRANSFER_AMOUNT_FORMAT_MESSAGE)
      .positive(MESSAGES.TRANSFER_AMOUNT_MIN_MESSAGE)
      .required(MESSAGES.SELECT_REQUIRE_MESSAGE),
    currency: yup.string().required(MESSAGES.SELECT_REQUIRE_MESSAGE),
  });
