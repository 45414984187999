import React, { useState } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { SelectFormik } from '../../../components/SelectCustom/SelectFormik';
import { TextFieldFormik } from '../../../components/TextFieldFormik/TextFieldFormik';
import { Form, Formik } from 'formik';
import {
  useAddDifferentServiceProviderLedger,
  useGetCurrencyOptions,
} from '../../../../services/Ledger';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { differentProviderLedgerValidationSchema } from '../../../../schemas/addDifferentProviderLedger.schema';

export const AddLedgerContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
}));

export const AddLedgerContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1.81rem',
  marginBottom: '1.81rem',
  width: '100%',
  maxWidth: '50rem',
}));
const AddLedger = ({ ledgerDetails }) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const userId = searchParams.get('id');
  const { currencyOptions } = useGetCurrencyOptions();
  const [isLoading, setIsLoading] = useState(false);
  const useAddDiffSerProviderMut = useAddDifferentServiceProviderLedger();
  const handleSubmit = (values) => {
    setIsLoading(true);
    useAddDiffSerProviderMut
      .mutateAsync({
        ledgerDetails: {
          userId: userId,
          ...values,
        },
      })
      .then((res) => {
        swal({
          title: `Ledger added Success`,
          icon: `success`,
          text: `Ledger Added Successfully`,
          button: true,
        });
        history.push(`/userdetails/${userId}`);
      })
      .catch((err) => {
        swal({
          title: `Ledger Added Failed`,
          icon: `error`,
          text: `${err.response.data.message}`,
        });
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <Formik
      initialValues={{
        ledgerName: ledgerDetails?.ledger_meta?.association || '',
        ledgerId: ledgerDetails?.ledger_id || '',
        iban: ledgerDetails?.iban || '',
        bic: ledgerDetails?.bic_swift || '',
        balance: ledgerDetails?.amount || '',
        currency: ledgerDetails?.asset_type || '',
        fee: '',
      }}
      validationSchema={differentProviderLedgerValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <AddLedgerContainer>
              <Typography variant="sendMoneyTitle" mt="3.125rem" mb="2.812rem">
                {ledgerDetails ? 'Update' : 'Add'} Ledger From Other Service
                Provider
              </Typography>
              <AddLedgerContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Box sx={{ width: '100%', maxWidth: '24.06rem' }}>
                    <TextFieldFormik
                      placeholder="Type the account name"
                      label="LEDGER'S NAME"
                      name="ledgerName"
                      type="text"
                      dashboard
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ width: '100%', maxWidth: '24.06rem' }}>
                    <TextFieldFormik
                      placeholder="DE1234567890133456678899023"
                      label="IBAN\ACCOUNT NUMBER"
                      name="iban"
                      type="text"
                      dashboard
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Box sx={{ width: '100%', maxWidth: '24.125rem' }}>
                    <TextFieldFormik
                      placeholder="Enter the amount"
                      label="CURRENT AMOUNT"
                      name="balance"
                      dashboard
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ zIndex: 1, width: '100%', maxWidth: '24.06rem' }}>
                    <SelectFormik
                      placeholder="Select a currency"
                      label="Currency"
                      name="currency"
                      options={currencyOptions}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Box sx={{ width: '100%', maxWidth: '24.06rem' }}>
                    <TextFieldFormik
                      placeholder="Enter the number"
                      label="BIC CODE"
                      name="bic"
                      type="text"
                      dashboard
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ width: '100%', maxWidth: '24.125rem' }}>
                    <TextFieldFormik
                      placeholder="Enter Ledger Id"
                      label="LEDGER ID"
                      name="ledgerId"
                      type="text"
                      dashboard
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '20px',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Box sx={{ width: '100%', maxWidth: '24.06rem' }}>
                    <TextFieldFormik
                      placeholder="Enter the fees in percentage"
                      label="FEES(%)"
                      name="fee"
                      type="text"
                      dashboard
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ width: '100%', maxWidth: '24.06rem' }}></Box>
                </Box>
                <Stack direction="row" gap={6} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      maxWidth: '11.38rem',
                      alignSelf: 'end',
                      mt: '2.81rem',
                    }}
                    onClick={() => history.goBack()}
                  >
                    Prev
                  </Button>
                  <Button
                    component={LoadingButton}
                    variant="contained"
                    loading={isLoading}
                    sx={{
                      maxWidth: '11.38rem',
                      alignSelf: 'end',
                      mt: '2.81rem',
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Stack>
              </AddLedgerContent>
            </AddLedgerContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AddLedger;
