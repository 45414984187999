import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { formatCurrency, formatLocaleDateTime } from 'assets/utils';
import React from 'react';
import { TransactionStatus, capitalize } from './TransactionStatus';
import TransactionLabel, { txnType } from './TransactionLabel';
import { TransactionAmount } from './TransactionAmount';
import { tirnuModalBg } from 'jsx/pages/Accounts/AccountTopup';
import { internationalCountries } from 'jsx/pages/SendMoney/SendMoneyTab/International';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionInfo = ({
  open,
  handleClose,
  txnInfo,
  isFees,
  activeIBAN,
  hideAmt,
}) => {
  if (!txnInfo) {
    return null;
  }
  const {
    _id: transaction_id,
    transaction_type,
    status: transaction_status,
    createdAt: txn_date_time,
    transaction_meta,
    transaction_fees,
    amount,
    overseas_receiver,
  } = txnInfo;

  const receiverName = txnInfo?.receiver_details?.user.name;
  const description = txnInfo?.description;
  const receiverAcc = txnInfo.receiver_details?.account_number;
  const receiverIBAN = txnInfo.transaction_meta?.receiver_account?.iban;
  const senderName = txnInfo.sender_details?.name;
  const senderIBAN = txnInfo.sender_details?.account_number;

  const isReceiver =
    [receiverAcc, receiverIBAN].includes(activeIBAN) &&
    ![txnType.BUY_CRYPTO, txnType.LOAD_MONEY].includes(transaction_type);

  const isRefund = receiverName === 'Refunded Crypto Amount';
  const isPositive =
    isReceiver ||
    ['LOAD_MONEY', 'SELL_CRYPTO_TRANSACTION'].includes(transaction_type);

  const conversionCurrency = internationalCountries.find(
    (country) => country.value === overseas_receiver?.transferToCountry
  )?.currency;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="txn-description"
      PaperProps={{
        sx: {
          borderRadius: 2.5,
          boxShadow: 'none',
          maxWidth: '33rem',
          textWrap: 'wrap',
        },
      }}
      sx={{
        background: tirnuModalBg,
      }}
    >
      <DialogContent>
        <Stack gap={2}>
          <Grid
            container
            gap={1}
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            {!hideAmt && (
              <Grid item tablet={12}>
                {/* <TransactionAmount
                  isLargeFont
                  isCenter
                  amount={isFees ? transaction_fees?.feeAmount : amount}
                  fee={0}
                  isPositive={isFees ? false : isPositive}
                  transaction_type={transaction_type}
                  transaction_status={transaction_status}
                /> */}
              </Grid>
            )}
            <Grid item tablet={12}>
              <Stack alignItems="center">
                <h2 className="m-0">Transaction</h2>
                {!hideAmt && (
                  <TransactionStatus
                    transaction_meta={transaction_meta}
                    transaction_status={
                      isFees
                        ? capitalize(transaction_fees.status)
                        : transaction_status
                    }
                    style={{ fontSize: '100px' }}
                  />
                )}
                {/* Date-Time Typography */}
              </Stack>
            </Grid>
          </Grid>

          <Divider />

          <Grid container gap={1}>
            <Grid item tablet={12}>
              <Stack direction="row" justifyContent="space-between" gap={3}>
                <Typography fontWeight="bold">From: </Typography>
                <Typography noWrap>{senderName}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" gap={10}>
                <Typography fontWeight="bold">Sender Iban: </Typography>
                <Typography noWrap>{senderIBAN}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">To: </Typography>
                <Typography>
                  {transaction_type === txnType.TXN_INTERNATIONAL
                    ? overseas_receiver.transferToAccountName
                    : receiverName}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Receiver Iban: </Typography>

                {transaction_type !== txnType.TXN_INTERNATIONAL ? (
                  <Typography noWrap> {receiverAcc} </Typography>
                ) : (
                  <Stack direction="column" alignItems="end" width="100%">
                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Bank: </Typography>
                      <Typography>
                        {overseas_receiver.transferToBank?.name}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">A/C No.: </Typography>
                      <Typography>{overseas_receiver.transferTo}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Amount: </Typography>
                      <Typography>{formatCurrency(amount)}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Exchange Rate: </Typography>
                      <Typography>
                        {conversionCurrency +
                          ' ' +
                          overseas_receiver.transferConversionRate[
                            '$numberDecimal'
                          ]}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">
                        Conversion Amount:{' '}
                      </Typography>
                      <Typography>
                        {conversionCurrency +
                          ' ' +
                          overseas_receiver.transferConversionAmount[
                            '$numberDecimal'
                          ]}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Contact: </Typography>
                      <Typography>
                        {overseas_receiver.transferToNumber
                          ? '+' + overseas_receiver.transferToNumber
                          : '-'}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Code: </Typography>
                      <Typography>{overseas_receiver.code}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">
                        Fulfillment Status:
                      </Typography>
                      <Typography>{overseas_receiver.status}</Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Type: </Typography>
                      <Typography>
                        {overseas_receiver.transferType === 'bank' &&
                          'Bank Transfer'}
                        {overseas_receiver.transferType === 'pickup' &&
                          'Cash Pickup'}
                        {!overseas_receiver.transferType && '-'}
                      </Typography>
                    </Stack>

                    <Stack
                      width="inherit"
                      direction="row"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Typography fontWeight="bold">Agent: </Typography>
                      <Typography>
                        {overseas_receiver.fulfillAgent
                          ? overseas_receiver.fulfillAgent?.full_name
                              .first_name +
                            ' ' +
                            overseas_receiver.fulfillAgent?.full_name.last_name
                          : '-'}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Amount</Typography>
                {/*<Typography*/}
                {/*  style={{*/}
                {/*    color: transaction_status === 'Failure' ? 'red' : 'green',*/}
                {/*    fontSize: '20px',*/}
                {/*  }}*/}
                {/*>*/}
                {/*  €{amount}*/}
                {/*</Typography>*/}
                <TransactionAmount
                  amount={amount}
                  fee={0}
                  isPositive={isFees ? false : isPositive}
                  transaction_type={transaction_type}
                  transaction_status={transaction_status}
                  style={{
                    color: transaction_status === 'FAILURE' ? 'red' : 'green',
                  }}
                />
              </Stack>

              {transaction_fees &&
                !(
                  [receiverAcc, receiverIBAN].includes(activeIBAN) &&
                  ![txnType.BUY_CRYPTO, txnType.LOAD_MONEY].includes(
                    transaction_type
                  )
                ) && (
                  <>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography fontWeight="bold">Fee</Typography>
                      <TransactionAmount
                        amount={transaction_fees?.feeAmount}
                        fee={0}
                        isPositive={isPositive}
                        transaction_type={transaction_type}
                        transaction_status={transaction_status}
                        style={{
                          color:
                            transaction_status === 'FAILURE' ? 'red' : 'green',
                        }}
                      />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography fontWeight="bold">Total</Typography>
                      <TransactionAmount
                        amount={transaction_fees?.feeAmount + amount}
                        fee={0}
                        isPositive={isPositive}
                        transaction_type={transaction_type}
                        transaction_status={transaction_status}
                        style={{
                          color:
                            transaction_status === 'FAILURE' ? 'red' : 'green',
                        }}
                      />
                    </Stack>
                  </>
                )}
              <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight="bold">Description: </Typography>
                <Typography ml={2}>{description}</Typography>
              </Stack>
            </Grid>
            {/* <Grid item tablet={12}>
            
            </Grid>
            <Grid item tablet={12}>
             
             
            </Grid> */}


          </Grid>


          <Divider />

          <Grid container gap={1}>
            {transaction_meta?.reference && (
              <Grid item tablet={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight="bold">Reference: </Typography>
                  <Typography>
                    {isFees
                      ? 'Dmonie Transfer Fee'
                      : transaction_meta?.reference ||
                        txnInfo?.description ||
                        ' - '}
                  </Typography>
                </Stack>
              </Grid>
            )}
            {!hideAmt && (
              <Grid item tablet={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight="bold">Type: </Typography>
                  <TransactionLabel
                    type={isFees ? txnType.FEES : transaction_type}
                    isReceiver={isReceiver}
                    isRefund={isRefund}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={3}>
                  <Typography fontWeight="bold">Transaction ID: </Typography>
                  <Typography>{transaction_id}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={3}>
                  <Typography fontWeight="bold">Date: </Typography>
                  <Typography fontSize={14}>
                    {formatLocaleDateTime(
                      isFees ? transaction_fees.createdAt : txn_date_time
                    )}
                  </Typography>
                </Stack>
                {/*<Stack direction="row" justifyContent="space-between" gap={3}>*/}
                {/*  <Typography fontWeight="bold">Reference: </Typography>*/}
                {/*  <Typography fontSize={14}>{description}</Typography>*/}
                {/*</Stack>*/}
                {/*  */}
              </Grid>
            )}
          </Grid>

          <Button
            variant="contained"
            color="primary"
            sx={{
              maxWidth: '100%',
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionInfo;
