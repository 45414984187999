import React, { Fragment, lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';

import Footer from './layouts/Footer';
import Nav from './layouts/nav';
import ScrollToTop from './layouts/ScrollToTop';
import './chart.css';
import './index.css';
import './step.css';
import { useCheckIsEmployeeQuery } from 'services/Employee';
import { useMediaQuery, useTheme } from '@mui/material';
import UserDetails from './pages/Admin/userList/UserDetails';
import DocumentsUpload from './pages/Admin/CreateUser/DocumentsUpload';
import AddLedger from './pages/Admin/Account/AddLedger';

const CardsBusiness = lazy(() => import('./pages/Cards/CardsBusiness'));
const CardsPersonal = lazy(() => import('./pages/Cards/CardsPersonal'));

const BusinessDashboard = lazy(() => import('./pages/Dashboard/Business'));
const ConsumerDashboard = lazy(() => import('./pages/Dashboard/Consumer'));
const AdminDashboard = lazy(() => import('./pages/Dashboard/Admin'));
const Account = lazy(() => import('./pages/Accounts/AccountList'));
const Profile = lazy(() => import('./pages/Profile/ProfileUpdate'));
const Transactions = lazy(() => import('./pages/Transactions'));
const AdminTransactions = lazy(() => import('./pages/Transactions/admin'));
const AdminTransactionDetail = lazy(() =>
  import('./pages/Transactions/admin/AdminTransactionDetails')
);
// const Wizard = lazy(() => import('./components/Forms/Wizard/Wizard'));
const BeneficiaryList = lazy(() =>
  import('./pages/Beneficiary/BeneficiaryList')
);
// const Card = lazy(() => import('./pages/Card'));
const BeneficiaryWizard = lazy(() =>
  import('./pages/Beneficiary/BeneficiaryForm')
);
const Integration = lazy(() => import('./pages/Integrations'));
const Notifications = lazy(() => import('./pages/notification'));
const NotificationDropDown = lazy(() => import('./components/notifications'));
const Investment = lazy(() => import('./pages/Investment/Investment'));
const EmployeeList = lazy(() => import('./pages/Employee/EmployeeList'));
const ReimbursementRequest = lazy(() => import('./pages/Reimbursement'));

const AccountCreation = lazy(() => import('./pages/Accounts/AccountCreation'));
const EmailConfig = lazy(() => import('./pages/EmailConfig'));
const SecurityConfig = lazy(() =>
  import('./pages/Dashboard/shared/Security/index')
);

const SendMoney = lazy(() => import('./pages/SendMoney/SendMoney'));
const AdminSendMoney = lazy(() => import('./pages/SendMoney/admin/SendMoney'));
const FeesSetup = lazy(() => import('./pages/FeesSetup'));
const ExchangeRate = lazy(() => import('./pages/ExchangeRate'));
const SystemUsers = lazy(() => import('./pages/Admin/userList/index'));
const UserFeesSetup = lazy(() => import('./pages/Admin/Feessetup/index'));
const AdminEarning = lazy(() => import('./pages/Admin/Earning/Adminearning'));
const Directors = lazy(() => import('./pages/Admin/CreateUser/Directors'));
const BusinessAccountForm = lazy(() =>
  import('./pages/Admin/CreateUser/CreateBusinessUserModal')
);
const PersonalAccountForm = lazy(() =>
  import('./pages/Admin/CreateUser/CreatePersonalUserModal')
);
const PersonalDocumentForm = lazy(() =>
  import('./pages/Admin/CreateUser/PersonalDocumentUpload')
);
const AdminCryptoBalanceDetails = lazy(() =>
  import('./pages/Investment/admin/AdminCryptoBalanceDetails')
);
const Feesdetails = lazy(() => import('./pages/Admin/Feessetup/Feesdetails'));

export const AccountType = {
  B: 'business',
  P: 'personal',
  S: 'super_admin',
  C: 'client',
  A: 'admin',
  AG: 'agent_nepal',
};

const Tirnu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const {
    path,
    activeEvent,
    setActiveEvent,
    windowWidth,
    user,
    isFeatureEnabled,
    features,
  } = useContext(ThemeContext);

  useCheckIsEmployeeQuery();

  const pagePath = path.split('-').includes('page');
  const HomePage =
    AccountType.B === user.accountType
      ? BusinessDashboard
      : AccountType.S === user.accountType ||
        AccountType.A === user.accountType ||
        AccountType.C === user.accountType
      ? AdminDashboard
      : ConsumerDashboard;
  const CardsPage =
    AccountType.B === user.accountType ? CardsBusiness : CardsPersonal;

  let routes = [
    { url: '', component: HomePage },
    { url: 'dashboard', component: HomePage },
    { url: 'accounts', component: Account },
    { url: 'profile', component: Profile },
    { url: 'transactions', component: Transactions },
    { url: 'notifications', component: Notifications },
    { url: 'beneficiary', component: BeneficiaryList },
    { url: 'send-money', component: SendMoney },
    { url: 'investment', component: Investment },
    { url: 'request-reimbursement', component: ReimbursementRequest },
    { url: 'add-beneficiary', component: BeneficiaryWizard },
    { url: 'edit-beneficiary/:beneficiary_id', component: BeneficiaryWizard },
    { url: 'email-config', component: EmailConfig },
    { url: 'security-config', component: SecurityConfig },
  ];

  if (isFeatureEnabled(features.cards)) {
    routes = [...routes, { url: 'cards', component: CardsPage }];
  }

  if (user.accountType === AccountType.S) {
    routes = [
      ...routes,
      { url: 'crypto-balance', component: AdminCryptoBalanceDetails },
      { url: 'remittance-transactions', component: Transactions },
      { url: 'exchange-rate', component: ExchangeRate },
      { url: 'fees-setup', component: FeesSetup },
      { url: 'fees-configuration', component: UserFeesSetup },
      { url: 'admin-earning', component: AdminEarning },
    ];
  }

  if (
    user.accountType === AccountType.S ||
    user.accountType === AccountType.A ||
    user.accountType === AccountType.C
  ) {
    routes = [
      ...routes,
      { url: 'admin-send-money', component: AdminSendMoney },
      { url: 'admin-transactions', component: AdminTransactions },
      { url: 'transactions-details', component: AdminTransactionDetail },
      { url: 'fees-details', component: Feesdetails },
      { url: 'users', component: SystemUsers },
      { url: 'userdetails/:id', component: UserDetails },
      { url: 'directors/:id', component: Directors },
      { url: 'documents/:id', component: DocumentsUpload },
      { url: 'add-ledger', component: AddLedger },
      { url: 'personal-form', component: PersonalAccountForm },
      { url: 'personal-form/:id', component: PersonalDocumentForm },
      { url: 'business-form', component: BusinessAccountForm },
    ];
  }

  if (user.accountType === AccountType.B) {
    routes = [
      ...routes,
      { url: 'integration', component: Integration },
      { url: 'employee-management', component: EmployeeList },
    ];
  }

  const toggleHeaderOnMobile = () => {
    if (isMobile) {
      let hamburgerIcon = document.querySelector('.nav-control .hamburger');
      hamburgerIcon.classList.remove('is-active');

      document.querySelector('#main-wrapper')?.classList.toggle('menu-toggle');
    }
  };

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => {
              setActiveEvent(!activeEvent);
              toggleHeaderOnMobile();
            }}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
            !pagePath ? 'content-body' : ''
          }`}
        >
          <div
            className={`${
              !pagePath
                ? `${windowWidth > 2000 ? 'container' : 'container-fluid'}`
                : ''
            }`}
            style={{ minHeight: window.screen.height - 30 }}
          >
            <NotificationDropDown />

            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              <Redirect to="/dashboard" />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>

      <AccountCreation />

      <ScrollToTop />
    </>
  );
};

export default Tirnu;
