import { Link } from 'react-router-dom';
import {
  accounts,
  dashboard,
  logout,
  supports,
  transactions,
  transfers,
  Earning,
  users,
} from './Icons';
import { AccountType } from '../../index';
import React, { useEffect, useState } from 'react';
import { MM } from './SideBar';
import AccountListSidebar from 'jsx/pages/Accounts/AccountList/AccountListSidebar';
import IBAN from 'iban';
import axios from 'axios';
import { useIbanForAmount } from 'services/Ledger';
import AdminListSidebar from 'jsx/pages/Accounts/AccountList/AdminListSidebar';

const AdminSideBar = ({ user, path, logoutFun, props }) => {
  const { data: Iban } = useIbanForAmount();
   return (
    <div className="deznav bg-white">
      {/* <PerfectScrollbar className="deznav-scroll"> */}
      <MM className="metismenu" id="menu">
        <li
          className={`${
            path === 'dashboard' || path === '' ? 'mm-active' : ''
          }`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to="/dashboard">
            {dashboard}
            <span className="nav-text">Dashboard</span>
          </Link>
        </li>
        <li
          className={`${path === 'admin-transactions' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to={'/admin-transactions'}>
            {transactions}
            <span className="nav-text">Transactions</span>
          </Link>
        </li>
        <li
          className={`${path === 'users' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to="/users">
            {users}
            <span className="nav-text">Users</span>
          </Link>
        </li>
        <li
          className={`${path === 'fees-configuration' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to="/fees-configuration">
            {accounts}
            <span className="nav-text">Fees configuration</span>
          </Link>
        </li>
        <li
          className={`${path === 'admin-earning' ? 'mm-active' : ''}`}
          onClick={() => props.onClick()}
        >
          <Link className="has-arrow ai-icon" to="/admin-earning">
            {Earning}
            <span className="nav-text">Earning</span>
          </Link>
        </li>
        {<AdminListSidebar Iban={Iban} />}
        <li className={`${path === 'supports' ? 'mm-active' : ''}`}>
          <Link
            className="has-arrow ai-icon"
            to="#"
            onClick={() => props.onClick()}
          >
            {supports}
            <span className="nav-text">Supports</span>
          </Link>
        </li>
        <li className="log-out">
          <Link
            className="has-arrow ai-icon"
            to="#"
            onClick={() => logoutFun()}
          >
            {logout}
            <span className="nav-text">Log Out</span>
          </Link>
        </li>
      </MM>
    </div>
  );
};
export default AdminSideBar;
